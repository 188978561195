import './index.css';
import React, { useState, useEffect } from 'react';

const Icon = ({ d }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="inline-block mr-2"
  >
    <path d={d} />
  </svg>
);

const LoadingSpinner = () => (
  <div className="flex justify-center items-center h-screen">
    <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-blue-500"></div>
  </div>
);

const PersonalHomepage = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [imagesLoaded, setImagesLoaded] = useState(false);

  useEffect(() => {
    const handleLoad = () => {
      setIsLoaded(true);
    };

    if (document.readyState === 'complete') {
      handleLoad();
    } else {
      window.addEventListener('load', handleLoad);
    }

    const timeoutId = setTimeout(() => {
      setIsLoaded(true);
      setImagesLoaded(true);
    }, 10000);

    return () => {
      window.removeEventListener('load', handleLoad);
      clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    const imagesToLoad = ['/logo512-nelo.png'];
    let loadedImages = 0;

    const imageLoadHandler = () => {
      loadedImages++;
      if (loadedImages === imagesToLoad.length) {
        setImagesLoaded(true);
      }
    };

    imagesToLoad.forEach(src => {
      const img = new Image();
      img.src = src;
      img.onload = imageLoadHandler;
      img.onerror = imageLoadHandler;
    });
  }, []);

  if (!isLoaded || !imagesLoaded) {
    return <LoadingSpinner />;
  }

  return (
    <div className="min-h-screen bg-blue-100 flex items-center justify-center">
      <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
        <div className="flex items-center space-x-4 mb-6">
          <img
            src="/logo512-nelo.png"
            alt="nelogika's avatar"
            className="w-24 h-24 rounded-full"
          />
          <div>
            <h1 className="text-2xl font-bold">nelogika</h1>
            <p className="text-gray-600">VRChatter</p>
          </div>
        </div>
        <div className="flex justify-center space-x-4">
          <a
            href="https://twitter.com/nelogikaj"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center justify-center bg-blue-100 hover:bg-blue-200 text-blue-600 font-semibold py-2 px-4 rounded-full transition duration-300 ease-in-out"
          >
            <Icon d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z" />
            Twitter
          </a>
          <a
            href="https://vrchat.com/home/user/usr_5991d4b5-8775-4564-bf6e-375d4c8a00d3"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center justify-center bg-purple-100 hover:bg-purple-200 text-purple-600 font-semibold py-2 px-4 rounded-full transition duration-300 ease-in-out"
          >
            <Icon d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z M12 7a3 3 0 1 0 0 6 3 3 0 0 0 0-6z" />
            VRChat
          </a>
        </div>
      </div>
    </div>
  );
};

export default PersonalHomepage;